import React from "react";
import BuscaFirma from "../../../../components/MainServices/BuscaFirma";

export default (props) => {
	return (
		<>
			<BuscaFirma />
		</>
	);
};
