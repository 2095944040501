import React, { Fragment, Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import BuscaFirma from "../Pages/Services/BuscaFirma";
import BuscaProtesto from "../Pages/Services/BuscaProtesto";

import "./App.css";

class App extends Component {
	render() {
		return (
			<Fragment>
				<BrowserRouter>
					<ToastContainer autoClose={2500} position="top-center" />
					<Switch>
						<Route exact path="/services/busca-firma" component={BuscaFirma} />

						<Route
							exact
							path="/services/busca-protesto"
							component={BuscaProtesto}
						/>
					</Switch>
				</BrowserRouter>
			</Fragment>
		);
	}
}

export default App;
