import React from "react";

import Form from "./Form";
import Dialog from "./Dialog";
import Loader from "../../Loader";

import { get } from "../../../services/Protesto";

import { maskCpfCnpj } from "../../../utils/funcoes";

const initialValues = {
	select: "",
	input: "",
	date: "",
	color: "",
	text: "",
};

export default (props) => {
	const [open, setOpen] = React.useState(false);
	const [loader, setLoader] = React.useState(false);
	const [result, setResult] = React.useState(initialValues);
	const [form, setForm] = React.useState({
		select: "cpf",
		input: "",
	});

	const handleOpen = async (value) => {
		setOpen(value);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const getProtesto = async (form) => {
		const params = {
			[form.select]: form.input,
		};

		setLoader(true);
		const res = await get(params);
		setLoader(false);

		if (res) {
			let now = new Date();

			if (res.qtde <= 0)
				setResult({
					...form,
					date: now.toLocaleString(),
					color: "green",
					text:
						"Não constam protestos relacionados a este número de documento.",
				});
			else
				setResult({
					...form,
					date: now.toLocaleString(),
					color: "red",
					text: "Constam protestos relacionados a este número de documento.",
				});

			handleOpen(true);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name === "select") {
			setForm({
				select: value,
				input: "",
			});
		} else {
			if (form.select === "cpf" && value.length > 14) return;
			if (form.select === "cnpj" && value.length > 18) return;

			setForm({
				...form,
				[name]: maskCpfCnpj(value),
			});
		}
	};

	const handleClick = () => {
		if (!form.input) {
			return;
		}

		getProtesto(form);
	};

	return (
		<>
			<Loader loader={loader} />

			<Form
				{...props}
				form={form}
				handleClick={handleClick}
				handleChange={handleChange}
			/>

			<Dialog
				{...props}
				handleOpen={handleOpen}
				handleClose={handleClose}
				open={open}
				result={result}
			/>
		</>
	);
};
