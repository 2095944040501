import React from "react";

import Form from "./Form";
import Dialog from "./Dialog";
import Loader from "../../Loader";

import { get } from "../../../services/Firma";
import { maskCpfCnpj } from "../../../utils/funcoes";

export default (props) => {
	const [open, setOpen] = React.useState(false);
	const [page, setPage] = React.useState(0);
	const [rows, setRows] = React.useState([]);
	const [loader, setLoader] = React.useState(false);
	const [form, setForm] = React.useState({
		select: "nome",
		input: "",
	});

	const handleOpen = async (value) => {
		setOpen(value);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const fetchMoreData = () => {
		const newPage = page + 1;
		setPage(newPage);
		getFirmas(newPage);
	};

	const getFirmas = async (newPage) => {
		const params = {
			[form.select]: form.input,
			page: newPage,
		};

		setLoader(true);
		const res = await get(params);
		setLoader(false);

		if (res) {
			const moreRows = [...rows, ...res];
			newPage > 0 ? setRows(moreRows) : setRows(res);
			handleOpen(true);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name === "select") {
			setForm({
				select: value,
				input: "",
			});
		} else {
			setForm({
				...form,
				[name]: form.select === "cpf" ? maskCpfCnpj(value) : value,
			});
		}
	};

	const handleClick = () => {
		if (!form.input) {
			return;
		}

		setRows([]);
		getFirmas(0);
	};

	return (
		<>
			<Loader loader={loader} />

			<Form
				{...props}
				form={form}
				handleClick={handleClick}
				handleChange={handleChange}
			/>

			<Dialog
				{...props}
				handleOpen={handleOpen}
				handleClose={handleClose}
				fetchMoreData={fetchMoreData}
				open={open}
				rows={rows}
			/>
		</>
	);
};
