import React from "react";
import BuscaProtesto from "../../../../components/MainServices/BuscaProtesto";

export default (props) => {
	return (
		<>
			<BuscaProtesto />
		</>
	);
};
