const hexToBase64 = (str) => {
	return btoa(
		String.fromCharCode.apply(
			null,
			str
				.replace(/\r|\n/g, "")
				.replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
				.replace(/ +$/, "")
				.split(" ")
		)
	);
};

const removeMaskMoney = (value) => {
	if (Number(value) || !value) {
		return value;
	} else {
		return value.replace("R$", "").replace(".", "").replace(",", ".");
	}
};

const formatNumber = (number) => {
	if (number || number === 0) {
		return number
			.toFixed(2)
			.replace(".", ",")
			.split("")
			.reverse()
			.map((v, i) => (i > 5 && (i + 6) % 3 === 0 ? `${v}.` : v))
			.reverse()
			.join("");
	} else {
		return number;
	}
};

const emailIsValid = (email) => {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const maskCpfCnpj = (value) => {
	//value = value.replace(/\D/g, "");

	if (value.length <= 14) {
		value = value.replace(/\D/g, "");
		//CPF
		value = value.replace(/(\d{3})(\d)/, "$1.$2");
		value = value.replace(/(\d{3})(\d)/, "$1.$2");
		value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
	} else {
		value = value.replace(/\D/g, "");
		//CNPJ
		value = value.replace(/^(\d{2})(\d)/, "$1.$2");
		value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
		value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
		value = value.replace(/(\d{4})(\d)/, "$1-$2");
	}

	return value;
};

const maskCelular = (value) => {
	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "($1) $2");
	value = value.replace(/(\d{5})(\d)/, "$1 - $2");

	return value;
};

const CpfCnpjIsValid = (value) => {
	if (value.length === 14) {
		var cpf = value.trim();

		cpf = cpf.replace(/\./g, "");
		cpf = cpf.replace("-", "");
		cpf = cpf.split("").map(Number);

		let v1 = 0;
		let v2 = 0;
		let aux = false;

		for (let i = 1; cpf.length > i; i++) {
			if (cpf[i - 1] !== cpf[i]) {
				aux = true;
			}
		}

		if (aux === false) {
			return false;
		}

		for (var i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
			v1 += cpf[i] * p;
		}

		v1 = (v1 * 10) % 11;

		if (v1 === 10) {
			v1 = 0;
		}

		if (v1 !== cpf[9]) {
			return false;
		}
		for (let i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
			v2 += cpf[i] * p;
		}

		v2 = (v2 * 10) % 11;

		if (v2 === 10) {
			v2 = 0;
		}

		if (v2 !== cpf[10]) {
			return false;
		} else {
			return true;
		}
	} else if (value.length === 18) {
		var cnpj = value.trim();

		cnpj = cnpj.replace(/\./g, "");
		cnpj = cnpj.replace("-", "");
		cnpj = cnpj.replace("/", "");
		cnpj = cnpj.split("").map(Number);

		let v1 = 0;
		let v2 = 0;
		let aux = false;

		for (let i = 1; cnpj.length > i; i++) {
			if (cnpj[i - 1] !== cnpj[i]) {
				aux = true;
			}
		}

		if (aux === false) {
			return false;
		}

		for (let i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
			if (p1 >= 2) {
				v1 += cnpj[i] * p1;
			} else {
				v1 += cnpj[i] * p2;
			}
		}

		v1 = v1 % 11;

		if (v1 < 2) {
			v1 = 0;
		} else {
			v1 = 11 - v1;
		}

		if (v1 !== cnpj[12]) {
			return false;
		}

		for (let i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
			if (p1 >= 2) {
				v2 += cnpj[i] * p1;
			} else {
				v2 += cnpj[i] * p2;
			}
		}

		v2 = v2 % 11;

		if (v2 < 2) {
			v2 = 0;
		} else {
			v2 = 11 - v2;
		}

		if (v2 !== cnpj[13]) {
			return false;
		} else {
			return true;
		}
	} else {
		return false;
	}
};

const filterArrayByString = (mainArr, searchText) => {
	if (searchText === "") {
		return mainArr;
	}

	searchText = searchText.toLowerCase();

	return mainArr.filter((itemObj) => {
		return searchInObj(itemObj, searchText);
	});
};

const searchInObj = (itemObj, searchText) => {
	for (const prop in itemObj) {
		if (!itemObj.hasOwnProperty(prop)) {
			continue;
		}

		const value = itemObj[prop];

		if (typeof value === "string") {
			if (searchInString(value, searchText)) {
				return true;
			}
		} else if (Array.isArray(value)) {
			if (searchInArray(value, searchText)) {
				return true;
			}
		}

		if (typeof value === "object") {
			if (searchInObj(value, searchText)) {
				return true;
			}
		}
	}
};

const searchInArray = (arr, searchText) => {
	for (const value of arr) {
		if (typeof value === "string") {
			if (searchInString(value, searchText)) {
				return true;
			}
		}

		if (typeof value === "object") {
			if (searchInObj(value, searchText)) {
				return true;
			}
		}
	}
};

const searchInString = (value, searchText) => {
	return value.toLowerCase().includes(searchText);
};

const calculateFinanciamento = (saldo, taxa, qtde) => {
	var numero2 = taxa / 100;
	var numero3 = 1 - Math.pow(1 / (1 + taxa / 100), qtde);

	if (taxa === 0) {
		return roundToTwo(saldo / qtde);
	} else return roundToTwo(saldo * (numero2 / numero3));
};

const roundToTwo = (num) => {
	return num.toFixed(2);
};

export {
	hexToBase64,
	formatNumber,
	emailIsValid,
	maskCpfCnpj,
	maskCelular,
	CpfCnpjIsValid,
	roundToTwo,
	filterArrayByString,
	removeMaskMoney,
	calculateFinanciamento,
};
