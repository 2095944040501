import axios from "axios";

const baseURL = "http://186.249.46.194:4001/api/";
// const baseURL = "http://localhost:3333/api/";

const API = axios.create({
	baseURL: baseURL,
	responseType: "json",
});

// HEADERS DEFAULT
const headersDefault = () => {
	let headers = {};
	headers["Content-Type"] = "application/json";
	return headers;
};

// REQUEST INTERCEPTOR
API.interceptors.request.use((config) => {
	config.params = config.params || {};
	config.headers = headersDefault();
	return config;
});

//RESPONSE INTERCEPTOR
API.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		console.log(error.response);
		return error;
	}
);

export { API };
