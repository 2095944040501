import React from "react";
import Loading from "react-loader-spinner";

import "./style.scss";

export default (props) => {
	return (
		<>
			{props.loader && (
				<div className="loader">
					<div className="loading">
						<Loading type="Oval" color="#272c3f" height={80} width={80} />
						<span style={{ color: "#272c3f" }}>Carregando</span>
					</div>
				</div>
			)}
		</>
	);
};
