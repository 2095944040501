import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import InfiniteScroll from "react-infinite-scroll-component";

import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";

import "./style.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default (props) => {
	return (
		<div>
			<Dialog
				open={props.open}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => props.handleOpen(false)}
			>
				<DialogTitle>
					Resultados da pesquisa
					<IconButton onClick={props.handleClose} className="btn__close">
						<Close />
					</IconButton>
				</DialogTitle>
				<div className="separator"></div>
				<DialogContent className="tableContent">
					{props.rows && props.rows.length > 0 ? (
						<TableContainer component={Paper}>
							<InfiniteScroll
								dataLength={props.rows.length}
								next={props.fetchMoreData}
								hasMore={true}
								scrollableTarget="tableContent"
							>
								<Table>
									<TableHead className="tableHead">
										<TableRow>
											<TableCell>#</TableCell>
											<TableCell>Nome</TableCell>
											<TableCell>CPF</TableCell>
											<TableCell>Data</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{props.rows.map((row, index) => (
											<TableRow key={index}>
												<TableCell className="tableCell">{index + 1}</TableCell>
												<TableCell className="tableCell">{row.nome}</TableCell>
												<TableCell className="tableCell">{row.cic}</TableCell>
												<TableCell className="tableCell">{row.data}</TableCell>

												<TableCell className="tableCellXs">
													<span>{index + 1}</span>
													<span>{row.nome}</span>
													<span>{row.cic}</span>
													<span>{row.data}</span>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</InfiniteScroll>
						</TableContainer>
					) : (
						<>
							<div>Cadastro não encontrado</div>
							<div>Não há cartão de assinatura para o nome/CPF indicado.</div>
						</>
					)}
				</DialogContent>
			</Dialog>
		</div>
	);
};
