import React from "react";

import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Slide,
	Paper,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import "./style.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default (props) => {
	return (
		<div>
			<Dialog
				open={props.open}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => props.handleOpen(false)}
			>
				<DialogTitle>
					Resultados da pesquisa
					<IconButton onClick={props.handleClose} className="btn__close">
						<Close />
					</IconButton>
				</DialogTitle>
				<div className="separator"></div>
				<DialogContent className="containerDialog">
					<Paper className="wrapperDialog">
						<h5 className="titleDialogProtesto">
							Informações sobre o documento pesquisado
						</h5>
						<div className="dateContainer">
							<p className="labelDialogProtesto">Data da pesquisa:</p>
							<p className="valueDialogProtesto">{props.result.date}</p>
						</div>

						<div className="documentContainer">
							<p className="labelDialogProtesto">
								{props.result.select.toUpperCase() + ":"}
							</p>
							<p className="valueDialogProtesto">{props.result.input}</p>
						</div>

						<div
							className="responseDialogProtesto"
							style={{
								color: props.result.color,
							}}
						>
							{props.result.text}
						</div>
						<p className="messageDialogProtesto">
							Esta busca não é válida como certidão.
						</p>
					</Paper>
				</DialogContent>
			</Dialog>
		</div>
	);
};
