import React from "react";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import "./style.scss";

export default (props) => {
	const options = [
		{ name: "Nome", value: "nome" },
		{ name: "CPF", value: "cpf" },
	];

	return (
		<>
			<div className={"MainServicesArea aboutAreaStyleTwo col-lg-12"}>
				<div className="col-lg-6">
					<div className="aboutContent">
						<div className="headerContainer">
							<h2>Cartão de Assinatura</h2>
							<div className="separatorForm"></div>
							<p>
								Verifique se a parte possui cadastro em nosso Tabelionato.
								<br />
								Informe o nome ou Nome/CPF que deseja pesquisar.
							</p>
						</div>

						<div className="form">
							<div>
								<div className="col-12 inputContainer">
									<div className="formInput">
										<TextField
											className="inputWidth"
											autoComplete="off"
											label="Selecione um tipo de pesquisa"
											name="select"
											value={props.form.select}
											onChange={props.handleChange}
											select
										>
											{options.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.name}
												</MenuItem>
											))}
										</TextField>
									</div>
								</div>

								<div className="col-12 inputContainer">
									<div className="formInput">
										<TextField
											className="inputWidth"
											autoComplete="off"
											type="text"
											label={`Informe o ${
												options.find((data) => data.value === props.form.select)
													.name
											}`}
											name="input"
											value={props.form.input}
											onChange={props.handleChange}
										/>
									</div>
								</div>
								<div>
									<div className="btnContainer">
										<button
											className="btnLarge"
											type="button"
											onClick={props.handleClick}
										>
											Consultar
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
